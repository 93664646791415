<template>
  <div class="amap-page-container">
    <el-amap
        vid="amapDemo"
        :center="mapInfo.lnglat"
        :amap-manager="amapManager"
        :zoom="zoom"
        :events="events"
        :plugin="plugin"
        class="amap-demo"
        style="height: 400px"
    >
      <el-amap-marker ref="marker" vid="component-marker" :position="mapInfo.lnglat"></el-amap-marker>
    </el-amap>
    <div>
      <van-field id="tipinput" placeholder="点击输入地址">
        <template #button>
          <van-button size="small" type="primary" @click="affirm">确认</van-button>
        </template>
      </van-field>

      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="award-o" :text="mapInfo.address"/>
      <van-cell title="经度" :value="mapInfo.lng" icon="location-o"/>
      <van-cell title="纬度" :value="mapInfo.lat" icon="location-o"/>
    </div>
    <van-popup v-model="inputPopupShow" position="bottom" :style="{ height: '60%' }">
    </van-popup>
  </div>
</template>

<script>

import {AMapManager, lazyAMapApiLoaderInstance} from 'vue-amap';
import {Field, Popup, Cell, NoticeBar, Button} from "vant";
import $store from "@/store";

const amapManager = new AMapManager();
export default {
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
  },
  data: function () {
    let self = this
    return {
      plugin: [   //一些工具插件
        {
          pName: 'Geolocation',   //定位
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  console.log(result)
                  self.mapInfo.lng = result.position.lng;             //设置经度
                  self.mapInfo.lat = result.position.lat;
                  self.mapInfo.address = result.formattedAddress//设置维度
                  self.mapInfo.lnglat = [result.position.lng, result.position.lat];         //设置坐标
                  self.$nextTick();                           //页面渲染好后
                }
              })
            }
          }
        },
        {
          pName: 'ToolBar',  //工具栏
          events: {
            init(instance) {
              // console.log(instance);
            }
          }
        },
        {
          pName: 'OverView',  //鹰眼
          events: {
            init(instance) {
              // console.log(instance);
            }
          }
        },
      ],
      inputPopupShow: false,
      mapInfo: {
        // 初始值默认为天安门
        address: '西藏自治区拉萨市城关区纳金街道江苏大道69号拉萨市人民政府',
        lng: 91.171924,
        lat: 29.653491,
        lnglat: [91.171924, 29.653491]
      },
      zoom: 12,
      amapManager,
      events: {
        init() {
          lazyAMapApiLoaderInstance.load().then(() => {
            // self.mounted()
          })
        },
        click: (e) => {
          this.mapInfo.lng = e.lnglat.lng;
          this.mapInfo.lat = e.lnglat.lat;
          this.mapInfo.lnglat = [e.lnglat.lng, e.lnglat.lat];
          this.getFormattedAddress();
        }
      }
    };
  },

  methods: {
    affirm() {
      $store.commit('SET_MAP_INFO', this.mapInfo)
      console.log($store.state.map.mapInfo)
      this.$router.back();
    },
    getFormattedAddress() {
      AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
          city: '全国'
        };
        let geocoder = new AMap.Geocoder(GeocoderOptions);
        console.log(geocoder);
        if (this.mapInfo.lnglat[0] !== undefined && this.mapInfo.lnglat[1] !== undefined) {
          geocoder.getAddress(this.mapInfo.lnglat, (status, result) => {
            console.log('通过经纬度拿到的地址', result);
            if (status === 'complete' && result.info === 'OK') {
              this.mapInfo.address = result.regeocode.formattedAddress;
            } else {
              this.mapInfo.address = '无法获取地址';
            }
          });
        }

      });
    },
    initMapByInput() {
      AMap.plugin('AMap.Autocomplete', () => {
        let autoOptions = {
          city: '全国',
          input: 'tipinput'
        };
        let autoComplete = new AMap.Autocomplete(autoOptions);
        console.log('通过经纬度拿到的地址');
        AMap.event.addListener(autoComplete, 'select', (e) => {
          console.log('通过输入拿到的地址', e);
          this.mapInfo.lat = e.poi.location.lat;
          this.mapInfo.lng = e.poi.location.lng;
          this.mapInfo.lnglat = [e.poi.location.lng, e.poi.location.lat];
          this.getFormattedAddress();
        });
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.initMapByInput()
    }, 200)
  }
};

</script>

<style lang="less">
.amap-demo {
  height: 300px;
}
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
